import React, { useEffect, useState } from 'react';
import Link from './Link';
import axios from 'axios';
import { SignOutButton } from "../SSO/SignOutButton";
import { SignInButton } from "../SSO/SignInButton";
import { useIsAuthenticated } from "@azure/msal-react";




const Sidebar = () => {
  const [navigation, setNavigation] = useState([]);
  const isAuthenticated = useIsAuthenticated();
  const fetchData = () => {
    axios.get("getNavigation").then((response) => {
      setNavigation(response.data.data)
    })
  }
  useEffect(() => {
    // fetchData()
  }, [])
  const logout = () => {
    localStorage.removeItem('token');
    window.location.reload(true);
  }
  const toggle = () => {
    // var btn = document.getElementById("header-btn");
    var sidebar = document.getElementById("sidebar");
    var main = document.getElementsByClassName("main");
    if (document.body.contains(sidebar) && main && sidebar.classList.contains("collapse-nav")) {
      sidebar.classList.remove("collapse-nav");

      // main.classList.remove("collapse-nav");
      for (var i = 0; i < main.length; i++) {
        main[i].classList.remove("collapse-nav")
      }
    } else {
      sidebar.classList.add("collapse-nav");
      for (var i = 0; i < main.length; i++) {
        main[i].classList.add("collapse-nav")
      }
    }
  };
  return (
    <>

      <aside id="sidebar" className="sidebar rounded-1 py-3 shadowd">

        <div className="d-flex align-items-center justify-content-between mb-4 mt-2 px-3 ">
          <a href="index.html" className="logo d-flex align-items-center">
          <img src={ "assets/images/logos/clogo.png"} alt="" width="160" height="100" />

          </a>
          <i class="fa-solid fa-xmark toggle-sidebar-btn fa-2xl bx-x" onClick={() => toggle()}></i>
        </div>
        <ul className="sidebar-nav pt-3" id="sidebar-nav">
          <>
            <Link path={"/"} icon={"fa-solid fa-home text-light"} label={"Home"} sublinks={[]} />
            <Link path={"/recognition"} icon={"fa-solid fa-users text-light"} label={"Recognition"} sublinks={[
              {
                url: "/thank-you",
                label: "\"Thank You\""
              },
              {
                url: "/we-awards",
                label: "\"We Awards\""
              },
              {
                url: "/acknowledge",
                label: "Acknowledge"
              },
              {
                url: "/quiz",
                label: "Quiz"
              },
              {
                url: "/my-ideas",
                label: "My Ideas"
              },
            ]} />

            <Link path={"/issue-logs"} icon={"fa-solid fa-list text-light"} label={"Issue Log"} sublinks={[]} />
            <Link path={"/help-support"} icon={"fa-solid fa-info text-light"} label={"Help & Support"} sublinks={[]} />
            <li className="nav-item">
              {isAuthenticated ? <SignOutButton show={true} /> :
                <a className="nav-link main-link" onClick={() => { logout() }}>
                  <span className="icon-shape icon ps-1">
                    <i className="fa-solid fa-right-from-bracket"></i>
                  </span>
                  <span className='ms-2 link-label text-dark d-flex w-100'> Signout</span>
                </a>
              }
            </li>
          </>
        </ul>
      </aside>

    </>
  )
}
export default Sidebar;
