import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const FeedItem = ({ showModal, closeModal }) => {

    const data = [
        {
            name: "Programme Design",
            description: "Form a cross-functional team to design the mentorship program. Define program goals, objectives, and key performance indicators (KPIs). Determine the program’s duration and frequency of mentorship meetings."
        },
        {
            name: "Mentor Selection",
            description: " Identify experienced and willing employees who can serve as mentors. They should possess the skills and knowledge relevant to the mentees’ needs."
        },
        {
            name: "Mentee Enrolment",
            description: "Open applications for mentees, allowing employees to express their interest in participating. Match mentors and mentees based on their goals, skills, and career interests."
        }, {
            name: "Training & Orientation",
            description: "Provide mentorship training to mentors, covering effective communication, coaching, and goal-setting techniques. Orient mentees on program expectations and resources available."
        }, {
            name: "Structured Meetings",
            description: "Encourage mentors and mentees to meet regularly. Define meeting agendas and encourage mentees to set clear objectives for their mentorship experience."
        }, {
            name: "Feedback & Evaluation",
            description: " Implement regular feedback mechanisms to assess the program’s effectiveness. Gather input from both mentors and mentees to make improvements."
        }, {
            name: "Celebrating Success",
            description: "Recognise and celebrate achievements and milestones reached  through the mentorship program. Share success stories within the organisation."
        },


    ]
    const data2 = [
        {
          name: "Knowledge Transfer",
          description:
            "Facilitates the transfer of institutional knowledge from experienced employees to newer ones.",
        },
        {
          name: "Professional Development",
          description: "Encourages continuous learning and skill development.",
        },
        {
          name: "Improved Employee Engagement",
          description:
            "Mentees feel valued and supported, leading to increased engagement and job satisfaction.",
        },
        {
          name: "Succession Planning",
          description:
            "Identifies potential future leaders and prepares them for leadership roles.",
        },
        {
          name: "Enhanced Company Culture",
          description: "Promotes a culture of collaboration, growth, and shared learning.",
        },
      ];
    return (
        <>

            <Modal show={showModal} onHide={() => { closeModal && closeModal() }} size='lg' className=''>
                <Modal.Header className='bg-primary w-100 text-light' closeButton>
                    <Modal.Title className='text-light' >
                        <h4> View More </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className=' r'>
                    <h5 className='text-primary'>My Idea Capture Form</h5>
                    <form className='form'>

                        <br />
                        <div class="row mb-4">
                            <div class="col-lg-3 col-md-3 col-sm-12">
                                <p>Idea Name:</p>
                            </div>

                            <div class="col-lg-9 col-md-9 col-sm-12">
                                <div className='border-primary border p-2 rounded-2'>
                                    Employee Mentorship Program
                                </div>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-lg-3 col-md-3 col-sm-12">
                                <p>Short Description:</p>
                            </div>

                            <div class="col-lg-9 col-md-9 col-sm-12">
                                <div className='border-primary border p-2 rounded-2'>
                                    Introduce a structured mentorship program to foster knowledge-sharing and professional
                                    growth among employees.
                                </div>
                            </div>
                        </div>

                        <div class="row mb-4">
                            <div class="col-lg-10 col-md-3 col-sm-12">
                                <p>Do you believe there would be a cost associated with the implementation of this idea?
                                    (Yes / No / Maybe)</p>
                            </div>

                            <div class="col-lg-2 col-md-9 col-sm-12">
                                <select className='border-primary border p-2 py-0 rounded-2 form-select'>
                                    <option className=''>
                                    Mayber
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="row mb-4">
                            <div class="col-lg-3 col-md-3 col-sm-12">
                                <p>Please share suggested approach to implement:</p>
                            </div>

                            <div class="col-lg-9 col-md-9 col-sm-12">
                                <div className='border-primary border p-2 rounded-2'>
                                    Mayber
                                </div>
                            </div>
                        </div>

                        <div class="row mb-4">
                            <div class="col-lg-3 col-md-3 col-sm-12">
                                <p>Please share suggested approach to implement:</p>
                            </div>

                            <div class="col-lg-9 col-md-9 col-sm-12">
                                <div className='border-primary border p-2 rounded-2'>
                                    {data.map((item, key) => (
                                        <>
                                            <p >
                                                <span className='font-weight-600 text-primary'>{item.name}: </span>
                                                {item.description}
                                            </p>
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div class="row mb-4">
                            <div class="col-lg-3 col-md-3 col-sm-12">
                                <p>
                                    Please share
                                    possible benefits of
                                    implementing this idea:
                                </p>
                            </div>

                            <div class="col-lg-9 col-md-9 col-sm-12">
                                <div className='border-primary border p-2 rounded-2'>
                                    {data2.map((item, key) => (
                                        <>
                                            <p >
                                                <span className='font-weight-600 text-primary'>{item.name}: </span>
                                                {item.description}
                                            </p>
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div class="row mb-4">
                            <div class="col-lg-3 col-md-3 col-sm-12">
                                <p>Please share any other information: (Optional)</p>
                            </div>

                            <div class="col-lg-9 col-md-9 col-sm-12">
                                <div className='border-primary border p-2 rounded-2'>
                                Consider incorporating a mentorship tracking system to monitor progress and outcomes. 
Provide mentors and mentees with resources and tools, such as templates for setting goals 
and conducting mentorship meetings, to make the process more structured and efficient.
                                </div>
                            </div>
                        </div>

                        {/* <div class="text-center">
                      <button type="submit" class="btn btn-primary">Change Password</button>
                    </div> */}
                    </form>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={() => { }}>
                        Close
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </>
    )
}
export default FeedItem;


