import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import { useNavigate } from 'react-router-dom';

const MyIdeas = () => {
const navigate =  useNavigate()


  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Recognition / My Ideas"} />
      <main id="main" className="main">
        <section className="section ">
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>
                <div className='card-body'>
                  <p>The My Ideas gives employees an opportunity to submits an idea to improve the way we do things, be it through innovation, process improvement
                    or IT enhancements. No idea is a bad idea and this is your chance to share those ideas.</p>
                  <p>
                    You can submit as many ideas as you like, as long as they have the potential to be implemented in our organisation.
                  </p>

                </div>
              </div>
            </div>

            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>

                <div className='card-body'>
                  <h5 className='fs-4'> My Idea Capture Form</h5>
                  <form className='mt-3'>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                        <label for="exampleInputPassword1" class="form-label">Idea Name:</label>
                        <input type="text" class="form-control" id="exampleInputPassword1" />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 mb-3 d-flex">
                        <label for="exampleInputPassword1" class="form-label">Do you believe there would be a cost associated with the implementation of this idea?
                          (Yes / No / Maybe)</label>
                        <select class="form-select" aria-label="Default select example">
                          <option ></option>
                          <option value="1">Yes</option>
                          <option value="2">No</option>
                          <option value="3">Maybe</option>
                        </select>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                        <label for="exampleInputPassword1" class="form-label">Short Description:</label>
                        <textarea type="text" class="form-control" id="exampleInputPassword1" > </textarea>
                      </div>


                      <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                        <label for="exampleInputPassword1" class="form-label">Please share
                          suggested approach
                          to implement:</label>
                        <textarea type="text" class="form-control" id="exampleInputPassword1" > </textarea>
                      </div>


                      <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                        <label for="exampleInputPassword1" class="form-label">Please share
                          possible benefits of
                          implementing this idea:</label>
                        <textarea type="text" class="form-control" id="exampleInputPassword1" > </textarea>
                      </div>


                      <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                        <label for="exampleInputPassword1" class="form-label">
                          Please share any other
                          information:
                          (Optional)</label>
                        <textarea type="text" class="form-control" id="exampleInputPassword1" > </textarea>
                      </div>
                    </div>
                    <div className='d-flex'>
                       <button onClick={()=>navigate("/")} className='btn btn-sm btn-secondary mx-2 ms-auto'>Save</button>
                       <button onClick={()=>navigate("/")} type='button' className='btn btn-sm btn-primary'>Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default MyIdeas;
