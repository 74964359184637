import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import { Button, Modal } from 'react-bootstrap';
import EmployeeInformation from './EmployeeInformation';

const ThankYou = () => {

  const [data, setData] = useState(EmployeeInformation);
  const [selectedIds, setSelectedIds] = useState([1]);
  const [showModal, setShowModal] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);

  var [values, setValues] = useState({
    value: "Joe Slovo", id: -1,
  })
  const openModal = (key) => {
    setShowModal(true)
    setFilteredItems(data);
  };

  const closeModal = () => {
    setShowModal(false)
  }

  const onCheck = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter(item => item !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };



  const handleSearchInput = (event) => {
    const query = event.target.value;

    // Filter the items based on the search query
    const filteredResult = filteredItems.filter((item) => {
      // Check if any of the values in the item object contain the search query
      for (const key in item) {
        const value = String(item[key]); // Convert the value to a string
        if (value.toLowerCase().includes(query.toLowerCase())) {
          return true; // Include the item if any value matches the query
        }
      }
      return false; // Exclude the item if no value matches the query
    });
    if (query == "") {
      setFilteredItems(data);
    } else {
      setFilteredItems(filteredResult);
    }
  };

  return (
    <>
      <Header label={"Recognition / Thank You!"} />
      <main id="main" className="main">
        <section className="section ">
          <div className='row'>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='card-body'>
                  <ul>
                    <li className='mb-2'>
                      This is an electronic non-monetary ‘Thank You’ programme used by staff to recognise each other for day-to-day actions that require
                      acknowledgement.
                    </li>

                    <li className='mb-2'>
                      Pre-defined “Thank You” options are recognition statements can be sent to employees thanking them for a job well done.
                    </li>

                    <li className='mb-2'>
                      <span className='font-weight-600'> Please note: </span> You are limited to send a maximum of 5 “Thank You” note per 7 working days. This is to help ensure only deservant accolates
                      are considered.
                    </li>
                  </ul>
                  <br />
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-6 d-flex mb-4">
                      <div className=''>
                        <input type='radio' name='group' className='me-3' />
                      </div>
                      <span className="m-auto message-card">
                        <div className='d-flex py-4'>
                          <i class="fa-solid fa-medal fa-2xl mx-auto text-secondary"></i>
                        </div>
                        <div className=''>
                          <p className='text-center font-size-11'> Thank you! You stepped up & saved the day!</p>
                        </div>

                      </span>
                    </div>


                    <div className="col-lg-4 col-md-4 col-sm-6 d-flex mb-4">
                      <div className=''>
                        <input type='radio' name='group' className='me-3' />
                      </div>
                      <span className="m-auto message-card">
                        <div className='d-flex py-4'>
                          <i class="fa-solid fa-star fa-2xl mx-auto text-secondary"></i>
                        </div>
                        <div className=''>
                          <p className='text-center font-size-11'>You are an absolute star!</p>
                        </div>

                      </span>
                    </div>


                    <div className="col-lg-4 col-md-4 col-sm-6 d-flex mb-4">
                      <div className=''>
                        <input type='radio' name='group' className='me-3' />
                      </div>
                      <span className="m-auto message-card">
                        <div className='d-flex py-4'>
                          <i class="fa-solid fa-masks-theater fa-2xl mx-auto text-secondary"></i>
                        </div>
                        <div className=''>
                          <p className='text-center font-size-11'>That star performance deserves a victory dance!</p>
                        </div>

                      </span>
                    </div>


                    <div className="col-lg-4 col-md-4 col-sm-6 d-flex mb-4" >
                      <div className=''>
                        <input type='radio' name='group' className='me-3' defaultChecked />
                      </div>
                      <span className="m-auto message-card">
                        <div className='d-flex py-4'>
                          <i class="fa-regular fa-star fa-2xl mx-auto text-secondary"></i>
                        </div>
                        <div className=''>
                          <p className='text-center font-size-11'>Thank you for helping us shine! You are a true star!</p>

                        </div>

                      </span>
                    </div>


                    <div className="col-lg-4 col-md-4 col-sm-6 d-flex mb-4">
                      <div className=''>
                        <input type='radio' name='group' className='me-3' />
                      </div>
                      <span className="m-auto message-card">
                        <div className='d-flex py-4'>
                          <i class="fa-solid fa-users fa-2xl mx-auto text-secondary"></i>
                        </div>
                        <div className=''>
                          <p className='text-center font-size-11'>Awesome team work! Thank you for putting in the extra effort.</p>
                        </div>

                      </span>
                    </div>


                    <div className="col-lg-4 col-md-4 col-sm-6 d-flex mb-4">
                      <div className=''>
                        <input type='radio' name='group' className='me-3' />
                      </div>
                      <span className="m-auto message-card">
                        <div className='d-flex py-4'>
                          <i class="fa-solid fa-hands-clapping fa-2xl mx-auto text-secondary"></i>
                        </div>
                        <div className=''>
                          <p className='text-center font-size-11'>You really stepped up your performance - Thanks all around!</p>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-lg-6'>
                <div className='card'>
                  <div className='card-body'>
                    <form>
                      <div class="mb-3 d-flex">
                        <button type="button" className='btn tex-primary align-self-start' onClick={() => { openModal() }}>
                          <i class="fa-solid fa-magnifying-glass fa-lg"></i>
                        </button>
                        <div className='flex-grow-1'>
                          <table class="table table-hover table-striped instance-table-header custom-table-2 ">
                            <thead>
                              <tr>
                                <th width="" scope="col" className="" id="issue_sub_category">Emp. No</th>
                                <th width="" scope="col" className="" id="subject">Name</th>
                                <th width="" scope="col" className="" id="logged_by_name">Last Name</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.map((item, key) => (
                                <>
                                  {selectedIds.includes(item.id) && (
                                    <>
                                      <tr>

                                        <td>{item.Emp_Number}</td>
                                        <td>{item.Name}</td>
                                        <td>{item.Last_Name}</td>
                                      </tr>
                                    </>
                                  )}
                                </>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">Add personal note (optional)</label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                        <div className='font-size-10 text-end'>Maximum 100 characters</div>
                      </div>
                      <div className='d-flex'>
                        <button type="button" class="btn  btn-sm py-1 bg-secondary ms-auto text-light me-2 ms-auto">Cancel</button>
                        <button type="button" class="btn btn-primary  btn-sm py-1 text-uppercase">Submit</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Modal show={showModal} onHide={closeModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>
            Employees
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search Items"
              onChange={handleSearchInput}
            />
          </div>

          <>
            <table class="table table-hover table-striped instance-table-header custom-table-2 ">
              <thead>
                <tr>
                  <th width="" scope="col" className="" id="issue_sub_category">Action</th>
                  <th width="" scope="col" className="" id="issue_sub_category">Emp. No</th>
                  <th width="" scope="col" className="" id="subject">Name</th>
                  <th width="" scope="col" className="" id="logged_by_name">Last Name</th>
                  <th width="" scope="col" className="" id="date_opened">Position</th>
                  <th width="" scope="col" className="" id="date_closed">Line Manager</th>
                </tr>
              </thead>
              <tbody>
                {filteredItems.map((item, key) => (
                  <>
                    <tr>
                      <td>
                        <div className='d-flex'>
                          <input onChange={() => { onCheck(item.id) }} checked={selectedIds.includes(item.id)} type="checkbox" />
                        </div>
                      </td>
                      <td>{item.Emp_Number}</td>
                      <td>{item.Name}</td>
                      <td>{item.Last_Name}</td>
                      <td>{item.Position}</td>
                      <td>{item.Line_Manager}</td>


                    </tr>
                  </>
                ))}
              </tbody>
            </table>
            <hr />
            <h5>Selected</h5>
            <table class="table table-hover table-striped instance-table-header custom-table-2 ">
              <thead>
                <tr>
                  <th width="" scope="col" className="" id="issue_sub_category">Action</th>
                  <th width="" scope="col" className="" id="issue_sub_category">Emp. No</th>
                  <th width="" scope="col" className="" id="subject">Name</th>
                  <th width="" scope="col" className="" id="logged_by_name">Last Name</th>
                  <th width="" scope="col" className="" id="date_opened">Position</th>
                  <th width="" scope="col" className="" id="date_closed">Line Manager</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, key) => (
                  <>
                    {selectedIds.includes(item.id) && (
                      <>
                        <tr>
                          <td>
                            <div className='d-flex'>
                              <input onChange={() => { onCheck(item.id) }} checked={selectedIds.includes(item.id)} type="checkbox" />
                            </div>
                          </td>
                          <td>{item.Emp_Number}</td>
                          <td>{item.Name}</td>
                          <td>{item.Last_Name}</td>
                          <td>{item.Position}</td>
                          <td>{item.Line_Manager}</td>
                        </tr>
                      </>
                    )}
                  </>
                ))}
              </tbody>
            </table>

          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default ThankYou;
