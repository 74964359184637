import React, { useEffect, useState } from 'react';
import Header from '../Header';
import FeedItem from './FeedItem';

const Dashboard = () => {
  const [post1, setPost1] = useState({
    liked: false,
    disliked: false
  })

  const [post2, setPost2] = useState({
    liked: false,
    disliked: false
  })

  const [post3, setPost3] = useState({
    liked: false,
    disliked: false
  })
const [showModal, setShowModal] = useState();

  const openModal = (key)=> {
    setShowModal(true)
  };
  const closeModal = () => {
    setShowModal(false)
  }
  

  return (
    <>
      <Header label={<h5 className='py-0 my-0'>Accolade Hub - Where Recognition Meets Results</h5>} />
      <main id="main" className="main">
        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-body'>
                <div className='content-block mt-2'>
                  <div className='h-4 card-content-headers mb-2'>
                    Welcome to the Employee Recognition system.
                  </div>

                  <div className=' mb-5'>
                    <p>
                      The implementation of Recognition programmes in our organisation aims to cultivate a work environment where the contributions and successes of individuals are openly celebrated.
                    </p>
                    <p>
                      Recognition fosters a culture in which our employees feel valued and appreciated. Providing employees with regular recognition and
                      commemorating their achievements (such as outstanding work, work anniversaries, promotions, birthdays, etc.) further enhances employee
                      engagement, satisfaction, and commitment.
                    </p>
                  </div>
                  <img className='fluid-image rounded' src='assets/images/employee-spans.png' />
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='card'>
              <div className='card-header font-weight-600 bg-secondary text-center rounded-2 text-center text-light fs-5'>
                My Accolades
              </div>
              <div className='card-body'>
                <br />
                <div className='card border rounded-2 shadow'>
                  <div className=' font-weight-600 bg-secondary ps-3 text-light'>
                    <div className="fs-6 py-2 ff-italic">Happy Work Anniversary</div>
                  </div>
                  <div className='card-body'>
                    <p className=" text-end font-style-italic font-size-11">07 Sep 2023</p>
                    <p className="">Lerato, congratulations on your <span className="font-weight-600">5 years</span> work anniversary!</p>
                    <p className="">We appreciate and value your contribution to our success! Happy more years to come!</p>
                  </div>
                </div>

                <div className='card border rounded-2 shadow'>
                  <div className=' font-weight-600 bg-secondary ps-3 text-light'>
                    <div className="fs-6 py-2 ff-italic">Happy Work Anniversary</div>
                  </div>
                  <div className='card-body'>
                    <div className="row">
                      <div className="col-4 d-flex">
                        <span className="m-auto message-card">
                          <div className='d-flex py-4'>
                            <i class="fa-solid fa-star fa-2xl mx-auto text-secondary"></i>
                          </div>
                          <div className=''>
                            <p className='text-center font-size-11'>You are an absolute star!</p>
                          </div>

                        </span>
                      </div>
                      <div className="col-8">
                        <p className=" text-end font-style-italic font-size-11">07 Sep 2023</p>
                        <p className=""><span className="font-weight-600">“Thank you!”</span> <span className=" font-style-italic"><small>By Jessica Parker!</small></span></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6'>
            <div className='card'>
              <div className='card-header font-weight-600 bg-secondary text-center rounded-2 text-center text-light fs-5'>
                My Feed
              </div>
              <div className='card-body'>
                <br />
                <div className='my-feed-card border p-2 rounded-2 shadow card'>
                  <div className='my-feed-footer d-flex'>
                    <p className='mb-2'><span className='font-weight-600'>I Innovate</span> - My Ideas</p>
                    <p className=" ms-auto font-style-italic font-size-11">07 Sep 2023</p>

                  </div>
                  <div className='my-feed-body d-flex'>
                    <p>
                      Implement a flexible work-from-home policy, allowing employees to better
                      balance work and personal life while maintaining productivity
                    </p>
                  </div>
                  <div className='my-feed-footer d-flex'>
                    <span className='like-dislike d-flex me-2' onClick={() => { setPost1({ disliked: false, liked: !post1.liked }) }}>
                      {post1.liked && <span className='p-0 m-auto mt-1 font-weight-600'>1</span>}
                      <i className={`fa-solid fa-thumbs-up fa-lg m-auto text-${post1.liked ? "warning" : "dark"}`} >

                      </i>
                    </span>

                    <span className='like-dislike d-flex' onClick={() => { setPost1({ liked: false, disliked: !post1.disliked }) }}>
                      {post1.disliked && <span className='p-0 m-auto mt-1 font-weight-600'>1</span>}
                      <i className={`fa-solid fa-thumbs-down fa-lg m-auto text-${post1.disliked ? "warning" : "dark"}`} ></i>
                    </span>
                    <a onClick={() => { }} className='text-dark font-weight-600 text-end text-underline font-style-italic ms-auto me-2'>
                      View Detail
                    </a>
                  </div>
                </div>


                <div className='my-feed-card border p-2 rounded-2 shadow card'>
                  <div className='my-feed-footer d-flex'>
                    <p className='mb-2'><span className='font-weight-600'>I Innovate</span> - My Ideas</p>
                    <p className=" ms-auto font-style-italic font-size-11">16 Sep 2023</p>

                  </div>
                  <div className='my-feed-body d-flex'>
                    <p>
                      Introduce a mentorship program to foster knowledge-sharing and
                      professional growth among employees.
                    </p>
                  </div>
                  <div className='my-feed-footer d-flex'>
                    <span className='like-dislike d-flex me-2' onClick={() => { setPost2({ disliked: false, liked: !post2.liked }) }}>
                      {post2.liked && <span className='p-0 m-auto mt-1 font-weight-600'>1</span>}
                      <i className={`fa-solid fa-thumbs-up fa-lg m-auto text-${post2.liked ? "warning" : "dark"}`} >

                      </i>
                    </span>

                    <span className='like-dislike d-flex' onClick={() => { setPost2({ liked: false, disliked: !post2.disliked }) }}>
                      {post2.disliked && <span className='p-0 m-auto mt-1 font-weight-600'>1</span>}
                      <i className={`fa-solid fa-thumbs-down fa-lg m-auto text-${post2.disliked ? "warning" : "dark"}`} ></i>
                    </span>
                    <a onClick={() => {openModal() }} className='text-dark font-weight-600 text-end text-underline font-style-italic ms-auto me-2'>
                      View Detail
                    </a>
                  </div>
                </div>

                <div className='my-feed-card border p-2 rounded-2 shadow card'>
                  <div className='my-feed-footer d-flex'>
                    <p className=" ms-auto font-style-italic font-size-11">16 Sep 2023</p>

                  </div>
                  <div className='my-feed-body'>
                    <div className="row mb-3">
                      <div className="col-4 d-flex">
                        <span className="m-auto message-card">
                          <div className='d-flex py-4'>
                            <i class="fa-solid fa-masks-theater fa-2xl mx-auto text-secondary"></i>
                          </div>
                          <div className=''>
                            <p className='text-center font-size-10'>That star performance deserves a victory dance!</p>
                          </div>

                        </span>
                      </div>
                      <div className="col-8">
                        <p className=""><span className="font-weight-600">“Thank you!”</span>  to: Sarah Jacobs <span className=" font-style-italic"><small>By Jessica Parker!</small></span></p>
                      </div>
                    </div>
                  </div>
                  <div className='my-feed-footer d-flex'>
                    <span className='like-dislike d-flex me-2' onClick={() => { setPost3({ disliked: false, liked: !post3.liked }) }}>
                      {post3.liked && <span className='p-0 m-auto mt-1 font-weight-600'>15</span>}
                      {!post3.liked && <span className='p-0 m-auto mt-1 font-weight-600'>14</span>}
                      <i className={`fa-solid fa-thumbs-up fa-lg m-auto text-${post3.liked ? "warning" : "dark"}`} >
                      </i>
                    </span>
                    <span className='like-dislike d-flex' onClick={() => { setPost3({ liked: false, disliked: !post3.disliked }) }}>
                      {post3.disliked && <span className='p-0 m-auto mt-1 font-weight-600'>1</span>}
                      <i className={`fa-solid fa-thumbs-down fa-lg m-auto text-${post3.disliked ? "warning" : "dark"}`} ></i>
                    </span>
                    <a onClick={() => { }} className='text-dark font-weight-600 text-end text-underline font-style-italic ms-auto me-2'>
                      View Detail
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <FeedItem showModal={showModal} closeModal={closeModal}/>
     
    </>
  )
}
export default Dashboard;
